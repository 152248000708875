import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import EnquiryForm from '../Pages/Enquiry'; 

const HeroSection = () => {
  const texts = [
    "Welcome to The Properties and Builders.",
    "Building Your Dreams, Brick by Brick.",
    "Your Trusted Partner in Real Estate."
  ];

  const [textIndex, setTextIndex] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    // Cycle through the texts every 5 seconds
    const timer = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const handleGetStartedClick = () => {
    setIsFormVisible(true); 
  };

  const closeForm = () => {
    setIsFormVisible(false); 
  };

  return (
    <div className="heroSection">
      <video className="heroVideo" autoPlay loop muted>
        <source src={require('../../assets/video/Hero.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="heroOverlay"></div>
      <div className="heroContent">
        <h1 className="hero-title">
          <span>{texts[textIndex]}</span>
        </h1>
        <p className="hero-text">
          Building Your Dreams with Integrity and Excellence.
        </p>
        <button className="hero-button" onClick={handleGetStartedClick}>Get Started</button>
      </div>
      {isFormVisible && <EnquiryForm service="Real Estate Services" closeForm={closeForm} />}
    </div>
  );
};

export default HeroSection;
